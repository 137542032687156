export const FirebaseConfig = {
	"projectId": "goviva-texi",
	"appId": "1:31213885942:web:f24c082c61a506aa478fd7",
	"databaseURL": "https://goviva-texi-default-rtdb.firebaseio.com",
	"storageBucket": "goviva-texi.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBMVVA3onXTwzeXoTNKUMv8wPvd32V0wyo",
	"authDomain": "goviva-texi.firebaseapp.com",
	"messagingSenderId": "31213885942",
	"measurementId": "G-RG3B8CXXEN"
};